import Form from "../../entity/form";

export default class FormSubmitterManager {
    constructor() {
        this.managed = [];
        this.needValidationFormIds = ['provider-contact-form'];
        this.initialize();
    }

    initialize() {
        $.each($("form[data-form-type='ajax']"), (i, form) => {
            let formObj = new Form($(form).attr("id"));
            let formId = $(form).attr("id");
            this.constructCallbackAjaxForm(formObj);
            if (typeof this.managed[formId] === 'undefined') {
                this.manage(formObj, true);
                this.managed[formId] = true;
            }
        });
    }

    manage(form) {
        switch (form.type) {
            case 'ajax':
                let formId = $('#' + form.id);
                if (formId.data('on-key-press-submit')) {
                    document.onkeypress = (e) => {
                        if (e.keyCode == 13) {
                            return false;
                        }
                    };
                    formId.on('keyup', (event) => {
                        if (!event.repeat) {
                            if (event.keyCode == 13) {
                                this.submitAjax(form);
                                event.preventDefault();
                                return false;
                            }
                        }
                    });
                }

                $('body').on("submit", '#' + form.id, (e) => {
                    if (form.validate()) {
                        this.submitAjax(form);
                    }
                    return false;
                });
                break;
            case 'default':
                break;
        }

    }

    async submitAjax(form) {
        $("#" + form.id + " .form-status").prop('disabled', true);
        let formElement = document.querySelector('#' + form.id);

        const response = await fetch(form.getAction(), {
            method: "POST",
            body: new FormData(formElement)
        }).then(r => r.json());

        form.executeCallback(response)
        return false;
    }

    constructCallbackAjaxForm(formObj) {
        let html = false;
        if ($('#' + formObj.id).data('form-callback-type') == 'html') {
            html = true;
        }
        formObj.setType("ajax");
        formObj.setCallback((data) => {
            if (!data) {
                return
            }
            let response = data;
            if (jQuery.type(data) != 'object') {
                response = JSON.parse(data);
            }

            if (response.routeToRedirect) {
                window.location.href = response.routeToRedirect;
            }

            if (response.status) {
                if (html) {
                    const formId = formObj.id;
                    const formObjId = $('#' + formId);
                    if (!($(formObjId.data('validated'))).length) {
                        const responseContainer = $('[name="response-container"]');
                        $(formObjId.data('form-response-container-title')).html(response.title).attr('data-type', response.status);
                        $(formObjId.data('form-response-container')).html(response.message);
                        $('[data-container="form-response-' + formId + '"]').addClass('form-response-' + response.status).show();
                        formObjId.trigger('onValidForm', [response, responseContainer]);
                        $('[data-container="footer-' + formId + '"]').addClass('form-response-' + response.status).show();
                        responseContainer.show();
                        $('[data-event="form-submit"][data-action="hide"]').hide();
                        if (this.needValidationFormIds.includes(formId)) {
                            $(formObjId.attr('data-validated', true));
                        }
                    }
                    formObjId.trigger('onFormSubmitted', response);
                } else {
                    myApp.notificationManager.showNotification(response.status, response.message);
                }
            }
            this.initialize();
            $("#" + formObj.id + " .form-status").prop('disabled', false);
            if (response.status == 200) {
                $("#" + formObj.id + " [data-form=autodestroy]").remove();
            }
        });
    }
}
