var DateFormatter = function () {

    "use strict";

    var self = this;


    this.getShortMonthName = function (date, language) {
        var options = {
            month: "short"
        };
        return date.toLocaleDateString(language, options);
    };
    
    this.getLongMonthName = function (date, language) {
        var options = {
            month: "long"
        };
        return date.toLocaleDateString(language, options);
    };

    this.getShortDate = function (date, separator = '/',excludeDays = false) {
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        if (excludeDays) {
            return year + separator + month;
        } else {
            return year + separator + month + separator + day;
        }

    }

    this.getDateFromDayMonthYear = function (dateString) {
        let dateParts = dateString.split(/\/|-/);
        return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
    }

};

export default DateFormatter;