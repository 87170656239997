import LazyLoad from "vanilla-lazyload";
import LazyLoadManagerWebcomponents from '@web/web-components/js/components/lazyLoadManager.js';
export default class LazyLoadManager extends LazyLoadManagerWebcomponents {
    initialize(container) {
        new LazyLoad({
            container,
            elements_selector: `.${LazyLoadManager.className}`,
            use_native: true,
        });
    }

    addLazyLoadAttributes(imageElement, imageUrl) {
        imageElement.setAttribute('data-src', imageUrl);
        imageElement.classList.add(LazyLoadManager.className);
        return imageElement;
    }
}

LazyLoadManager.className = 'lazy-load';
