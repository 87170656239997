export default class ScrollAffixManager {
    constructor() {
        this.eventDelegation();
    }

    eventDelegation() {
        const container = document.querySelector('[data-scroll-affix="container"]');
        const content = document.querySelector('[data-scroll-affix="content"]');
        const footer = document.querySelector('[data-entity="footer"]');

        if (!content || !container) {
            return;
        }
        content.style.width = container.getBoundingClientRect().width + "px";

        $(window).on("scroll", () => {
            if (container.getBoundingClientRect().bottom < 0) {
                content.classList.add("active");
                if (footer && footer.getBoundingClientRect().top < content.getBoundingClientRect().height) {
                    content.classList.remove("active");
                }
            } else {
                content.classList.remove("active");
            }
        });
    }
}
