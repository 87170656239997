var UrlManager = function () {
    "use strict";

    var self = this;

    this.getUrlParam = function (sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');

        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] == sParam) {
                return sParameterName[1];
            }
        }
    };

    this.checkUrlParam = function (param) {
        const urlParam = new URLSearchParams(window.location.search);
        return urlParam.has(param);
    };

    this.setUrlParam = function (param, value) {
        var url = document.location.href;
        if (this.checkUrlParam(param)) {
            var getParametersArray = window.location.search.substring(1).split('&');
            url = document.location.href.split('?')[0];

            $.each(getParametersArray, function (i, parameter) {
                var key = parameter.split('=')[0];
                if (key !== "") {
                    url = self.addCharacterToUrl(url);
                    if (key === param) {
                        parameter = self.buildParameter(param, value);
                    }
                    url = url + parameter;
                }
            });
        }
        else {
            url = self.addCharacterToUrl(url);
            url = url + this.buildParameter(param, value);
        }

        window.history.replaceState(history.state, "", url);
    };

    this.addCharacterToUrl = function (url) {
        var getParameters = url.split('?')[1];
        var character = "";
        if (typeof  getParameters == 'undefined') {
            character = "?"
        }
        if (typeof  getParameters != 'undefined' && getParameters !== "") {
            character = "&";
        }

        return url + character;
    };

    this.buildParameter = function (param, value) {
        var parameter = param;
        if (value !== "") {
            parameter = parameter + '=' + value;
        }

        return parameter;
    };

    this.removeUrlParam = function (param) {
        var getParametersArray = window.location.search.substring(1).split('&');
        var url = document.location.href.split('?')[0];

        $.each(getParametersArray, function (i, parameter) {
            var key = parameter.split('=')[0];
            if (key !== "" && key !== param) {
                url = self.addCharacterToUrl(url);
                url = url + parameter;
            }
        });

        window.history.replaceState(history.state, "", url);
    };
};

export default UrlManager;