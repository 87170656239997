import Swiper, {Navigation, Pagination, Lazy, Thumbs, FreeMode, Keyboard} from 'swiper';

export default class SliderManager {
    constructor() {
        this.touchDevice = false;
        this.test = this.touchDevice ?? null;
        if ("ontouchstart" in document.documentElement) {
            this.touchDevice = true;
        }
    }

    addSlider(slider, initFunction, paginationType = "fraction", paginationBullets = 1) {
        return new Swiper(
            slider,
            this._getParameters(slider, initFunction, paginationType, paginationBullets)
        );
    }

    _getParameters(slider, initFunction, paginationType, paginationBullets) {
        slider = document.querySelector(slider);
        let showArrowsTouchDevice = slider && slider.getAttribute('data-container') === "show-touch-device";
        let parameters = {
            modules: [Navigation, Pagination, Lazy, Thumbs, FreeMode, Keyboard],
            lazy: {
                loadPrevNext: true,
                enabled: true
            },
            on: {
                lazyImageReady: function(slide, image) {
                    if (initFunction) {
                        if ($(image).parent().find(':nth-child(2)')[0] === $(image)[0]) {
                            initFunction();
                        }
                    }
                }
            },
            spaceBetween: 5
        };
        if (paginationType === 'bullets') {
            parameters.pagination = {
                el: ".swiper-pagination",
                type: 'bullets',
                dynamicBullets: true,
                dynamicMainBullets: paginationBullets
            }
        }

        if (paginationType === 'fraction') {
            parameters.pagination = {
                el: ".swiper-pagination",
                type: 'fraction'
            }
        }

        if (!this.touchDevice || showArrowsTouchDevice) {
            parameters.navigation = {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                clickable: true
            };
        } else {
            if (slider) {
                let arrowNext = slider.querySelector('.swiper-button-next');
                let arrowPrev = slider.querySelector('.swiper-button-next');
                if (arrowNext) arrowNext.remove();
                if (arrowPrev) arrowPrev.remove();
            }
        }

        if (!slider) return;

        parameters.breakpoints = {};

        let breakpoints = Object.keys(myApp.myAppConfig.breakpoint).map(b => b.toLowerCase());
        for (let i = 0; i < breakpoints.length; i++) {
            if (slider.getAttribute('data-slides-' + breakpoints[i]) || slider.getAttribute('data-space-between-' + breakpoints[i])) {

                let slidesPerView = 1;
                if (slider.getAttribute('data-slides-' + breakpoints[i])) {
                    slidesPerView = parseFloat(slider.getAttribute('data-slides-' + breakpoints[i]));
                } else if (slider.getAttribute('data-slides')) {
                    slidesPerView = parseFloat(slider.getAttribute('data-slides'));
                }
                let spaceBetween = 0;
                if (slider.getAttribute('data-space-between-' + breakpoints[i])) {
                    spaceBetween = parseFloat(slider.getAttribute('data-space-between-' + breakpoints[i]));
                } else if (slider.getAttribute('data-space-between')) {
                    spaceBetween = parseFloat(slider.getAttribute('data-space-between'));
                }

                parameters.breakpoints[myApp.myAppConfig.breakpoint[breakpoints[i]] || 0] = {
                    slidesPerView,
                    spaceBetween
                };
            }
        }


        return parameters;
    }
}
