export default class GroupVideoManager {

    constructor() {
        this.eventDelegation();
    }

    eventDelegation() {

        let videos = document.querySelectorAll(".video-chollo-card");
        let videosPlayed = [];
        videos.forEach( function (video) {
            let observer = new IntersectionObserver(function (entries) {
                const videoId = video.getAttribute('data-id');
                if (entries[0].isIntersecting && video.paused && !videosPlayed.find(videosPlayed => videosPlayed.id == videoId)) {
                    var playPromise = video.play()
                    let videoObject = {
                        id: videoId,
                        isPlayed: playPromise
                    };
                    if (playPromise !== undefined) {
                        playPromise.then(() => {
                            if (!video.paused) {
                                videosPlayed.push(videoObject);
                            }
                        }).catch(() => {
                            video.play();
                        });
                    }
                } else if (videosPlayed.find(videosPlayed => videosPlayed.id == videoId)) {
                    if (!video.paused && videosPlayed.find(videosPlayed => videosPlayed.id == videoId).isPlayed) {
                        videosPlayed.find(videosPlayed => videosPlayed.id == videoId).isPlayed.then(() => {
                            video.pause();
                            videosPlayed = videosPlayed.filter(element => element.id !== videoId);
                        });
                    }
                }
            }, {threshold: 0.5});
            observer.observe(video);
        });

    }
}
