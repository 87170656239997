import AjaxManager from "../../ajaxManager";

export default class DataLayerManager {
    constructor() {
        this.ajaxManager = new AjaxManager();
    }

    push(type, parameters = null) {

        const url = myApp.config.routing.gtm.generator.dataLayer;

        let data = Object.assign({'type': type}, parameters);

        const callback = (response) => {
            this.pushData(response);
        };
        this.ajaxManager.ajaxCall('POST', url, data, callback);
    }

    pushData(data) {
        if (typeof dataLayer != 'undefined') {
            let dataParsed = JSON.parse(data);
            if (myApp.config.parameters.isOffice) {
                dataParsed.traffic_type = 'internal';
            }
            dataLayer.push(dataParsed);
        }
    }
}

DataLayerManager.prototype.type_client = 'dataLayerClient';
DataLayerManager.prototype.type_checkout = 'dataLayerCheckout';