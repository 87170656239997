var TranslatorService = function () {

    "use strict";

    var self = this;
    
    this.transchoice = function(string, amount) {
        if (amount > 1 || amount == 0) {
            return myApp.translator[string][1];
        } else {
            return myApp.translator[string][0];
        }
    };

};

export default TranslatorService;