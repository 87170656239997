import CustomMapBuilder from "../customMap/customMapBuilder";
import CustomMap from "../../entity/customMap/customMap";

export default class HotelManager {
    constructor() {
        this.customMapBuilder = new CustomMapBuilder();
        this.eventDelegation();
    }

    eventDelegation() {
        let mapModal = $('#mapModal');

        $('[data-entity="ratingContainer"]').on('click', () => {
            myApp.scrollManager.scrollToElement(
                '#ratingContainer'
            );
        })

        $('[data-action="showMapModal"]').on('click', (event) => {
            this.currentHotelId = $(event.currentTarget).closest('[data-id]').data('id');
            mapModal.find('.active-item').removeClass('active-item');
            mapModal.find('[name="hotel-'+this.currentHotelId +'"]').addClass('active-item');
            myApp.customModalManager.openModal('#mapModal');
            event.stopPropagation();
            let hotelId = event.currentTarget.dataset.id;
            let mapContainer = $('#'+ hotelId + '-custom-map-hotel-full');
            let mapId = mapContainer.data('hotel-map') || event.currentTarget.dataset.id;
            let map = this.customMapBuilder.getMap(mapId + "-custom-map-hotel-full");

            if (!(map instanceof CustomMap) || mapContainer.html()?.trim() === "") {
                if (map instanceof CustomMap) {
                    this.customMapBuilder.removeMap(mapId + "-custom-map-hotel-full");
                }
                map = this.buildFromDom(mapId, "-custom-map-hotel-full");
            }
            setTimeout(() => { map.invalidateSize()}, 200);
        });
    }

    buildFromDom(mapId, container) {
        let hotelData = $('[data-hotel-map="'+mapId+'"]');
        let latitude = $(hotelData).data('latitude');
        let longitude = $(hotelData).data('longitude');
        this.hotelMapId = mapId + container;
        let map = this.customMapBuilder.addMap(
            this.hotelMapId,
            latitude,
            longitude,
            15
        );
        map.invalidateSize()

        let markerId = mapId + container;
        this.markerList = [];

        const popupContent = $('[data-entity="popupContent-' + mapId + '"]').html();
        this.customMapBuilder.addMarker(
            this.hotelMapId,
            markerId,
            latitude,
            longitude,
            popupContent
        );

        map.addLayer();
        if (this.markerList.length > 1) {
            map.fitBounds(this.markerList);
        } else {
            map.invalidateSize();
        }
        map.fitBounds(this.markerList);
        map['openPopupByMarker'](markerId);

        map.map.on('popupopen', function(e) {
            $('[href="#close"]').removeAttr('href');
        });

        return map;
    }
}
