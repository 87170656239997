export default class CaptchaManager {

    constructor() {
        this.googleLibraryURL = 'https://www.google.com/recaptcha/api.js';
    }

    captchaInitialize(element, action = "homepage") {
        if (element.length == 0) {
            return;
        }

        const inputNameRecaptchaToken = "g-recaptcha-token";
        $(`input[name='${inputNameRecaptchaToken}']`).remove();

        $.getScript(`${this.googleLibraryURL}?render=${myApp.config.parameters.publicCaptchaKey}`, function () {
            grecaptcha.ready(function () {
                grecaptcha.execute(myApp.config.parameters.publicCaptchaKey, {action: action}).then(function (token) {
                    element.append(`<input type="hidden" name="${inputNameRecaptchaToken}" value="${token}">`);
                });
            });
        });
    }

    async executeCaptcha(form, action = "homepage") {
        const token = await grecaptcha.execute(myApp.config.parameters.publicCaptchaKey, {action: action});
        this.appendInput(form, token);
    }

    appendInput(form, token) {
        const inputNameRecaptchaToken = "g-recaptcha-token";
        const currentInput = form.querySelector(`[name="${inputNameRecaptchaToken}"]`);
        if (currentInput) currentInput.remove();

        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = inputNameRecaptchaToken;
        input.value = token;
        form.append(input);
    }
}
