var BrowserStorageService = function () {
    "use strict";

    var self = this;
    var browserStorageAvailable = false;
    var value;

    this.initialize = function () {
        this.initializeEventDelegation();
        this.eventDelegation();        
    };

    this.eventDelegation = function () {
        if (!this.initializeEventDelegation()) {
            $('.distance_sorter_element').addClass('hidden');
        }

        if (this.getItem('geolocation') == false) {
            $('.distance_sorter_element').addClass('hidden');
        }

        if (this.getItem('city')) {
            if (this.getItem('city') != "undefined") {
                $('#city').text(this.getItem('city'));
            } else {
                $('#city').text("Ubicación");
            }
        }

        $("[data-action='save-cookie']").on("click", function () {
            self.saveCookie($(this));
        });
        
        self.visibleContainerIfCookieExists();
    };

    this.initializeEventDelegation = function () {
        self.checkAvailability();
        return browserStorageAvailable;
    };

    this.setItem = function (item, value) {
        if (browserStorageAvailable) {
            localStorage.setItem(item, value);
        }
    };

    this.getItem = function (item) {
        if(browserStorageAvailable) {
            value = localStorage.getItem(item);
            return value;
        }
    };

    this.removeItem = function (item) {
        localStorage.removeItem(item);
    }

    this.checkAvailability = function () {
        try {
            localStorage.test = true;
            browserStorageAvailable = true;
        } catch (e) {
            browserStorageAvailable = false;
        }
    };

    this.getBrowserStorageAvailable = function () {
        return browserStorageAvailable;
    }
    
    this.visibleContainerIfCookieExists = function () {
        $.each($("[data-has-cookie]"), function (i, container) {
            var cookieName = $(container).find('[data-action="save-cookie"]').data('cookie-name');
            $(container).show();
            if (self.getCookie(cookieName) !== "") {
                $(container).hide();
            }
        });
    };
    
    this.saveCookie = function (element) {
        var cookieName = element.data('cookie-name');
        var expirationTime = element.data('cookie-expiration');
        if (this.getCookie(cookieName) === "") {
            this.setCookie(cookieName, expirationTime);
        }
    };
    
    this.getCookie = function (cookieName) {
        var name = cookieName + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    };

    this.findCookie = function (nameCookie) {
        const strCookie = document.cookie;
        const listCookies = strCookie.split("; ").map((row) =>{
            const arrayRow = row.split('=');
            return {
                'key': arrayRow[0],
                'value': arrayRow[1]
            }
        });
        const cookieMatches = listCookies.find(element => nameCookie === element.key);

        return cookieMatches ? cookieMatches : null;
    }


    this.setCookie = function (cookieName, expirationTime) {
        var date = new Date();
        var expires = expirationTime.split("-");
        switch (expires[1]) {
            case 'h':
                date.setHours(date.getHours()+parseInt(expires[0]));
                break;
            case 'd':
                date.setDate(date.getDate()+parseInt(expires[0]));
                break;
            case 'm':
                date.setMonth(date.getMonth()+parseInt(expires[0]));
                break;
            case 'y':
                date.setFullYear(date.getFullYear()+parseInt(expires[0]));
                break;
        }
        document.cookie = `${cookieName}=1; path=/; expires=${date.toUTCString()}`;
    };
    this.initialize();
};

export default BrowserStorageService;
