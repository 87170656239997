import BookingCalendarManager from "@web/web-components/js/components/calendar/bookingCalendarManager";
import DestinationManager from "@web/vpt-assets/assets/js/modules/destination/destinationManager";
import flatpickr from "flatpickr";
import {Spanish} from "flatpickr/dist/l10n/es.js";
import {Catalan} from "flatpickr/dist/l10n/cat.js";
import {French} from "flatpickr/dist/l10n/fr.js";
import {Portuguese} from "flatpickr/dist/l10n/pt.js";
import {German} from "flatpickr/dist/l10n/de.js";
import {Italian} from "flatpickr/dist/l10n/it.js";
export default class AmimirSearcher {
    constructor() {
        this.id = '#product-searcher-amimir'
        this.submitButtonId = '#amimir-searcher-button';
        this.submitButtonText = '[data-entity="amimir-search-btn-text"]';
        this.destinationInputId = '#amimir-searcher-text';
        this.calendarId = '#amimir-widget-dates';
        this.destinationManager = new DestinationManager()
        this.lastText = null;
        this.currentText = null;
        this.suggesting = [];
        this.destinationParams = [
            'hotelId',
            'highlightHotel',
            'cityId',
            'regionId'
        ];
        this.form = document.querySelector(this.id);

        if (!this.form) return;

        this.startDate = myApp.dateFormatter.getDateFromDayMonthYear(document.querySelector(this.id + ' input[name="start"]').value);
        this.endDate = myApp.dateFormatter.getDateFromDayMonthYear(document.querySelector(this.id + ' input[name="end"]').value);

        let calendarWidget = document.querySelector(this.calendarId);
        if (calendarWidget) {
            this.calendarData = JSON.parse(calendarWidget.getAttribute('data'));

            this.bookingCalendarManager = new BookingCalendarManager(
                flatpickr,
                {Spanish, Catalan, French, Portuguese, German, Italian},
                this.calendarData,
                myApp.config.localeCode,
                this.onOpenCalendar.bind(this),
                this.onCloseCalendar.bind(this)
            );
            this.setDefaultDate();
        }

        this.eventDelegation();
    }

    eventDelegation() {
        const destinationInput = this.form.querySelector(this.destinationInputId);
        const destinationContainer = destinationInput.closest('[data-dropdown="destination"]');
        if (!destinationContainer) return;

        this.form.querySelector(this.submitButtonId).addEventListener('click', (e) => {
            e.stopPropagation();
            e.preventDefault();
            if (this.validateDates() && this.validateDestination(destinationContainer)) {
                const buttonText = document.querySelector(this.submitButtonText);
                const spinner = this.form.querySelector(this.submitButtonId + ' [data-button-spinner]');
                buttonText.innerHTML = myApp.translator['search.amimir.button.loader'];
                spinner.style.display = '';
                setTimeout(() => {
                    buttonText.innerHTML = myApp.translator['forms.generic.search'];
                    spinner.style.display = 'none';
                    this.form.submit();
                }, 3000);

            }
        });

        destinationInput.addEventListener('keyup', (e) => {
            destinationContainer.classList.remove('border-error');
            this.currentText = e.currentTarget.value.trim();
            this.form.querySelector('[data-target="destination-spinner"]').classList.remove('hidden');
            this.resetDestinations();

            if (this.currentText.length < 3) {
                this.form.querySelector('[data-target="destination-spinner"]').classList.remove('hidden');
                return;
            }

            setTimeout(() => {
                if (this.currentText && (this.lastText === null || this.lastText !== this.currentText)) {
                    this.suggesting.push(this.currentText);
                    this.suggest(this.currentText).then(this.responseAction.bind(this));
                    this.lastText = this.currentText;
                }

            }, 1000);
        });
    }

    responseAction(r) {
        const response = JSON.parse(r);
        let destinationsContainer = document.querySelector('[data-destinations-container]');
        destinationsContainer.innerHTML = "";

        if (response.destinations && response.destinations.length > 0) {
            this.printDestinations(response.destinations, destinationsContainer);
        } else {
            if (response.ciutats && response.ciutats.length > 0) {
                this.printDestinations(response.ciutats, destinationsContainer);
            }
            if (response.regions && response.regions.length > 0) {
                this.printDestinations(response.regions, destinationsContainer);
            }
        }
        if (response.hotels && response.hotels.length > 0) {
            this.printDestinations(response.hotels, destinationsContainer);
        }
        if (!this.form.querySelector('[data-dropdown="destination"]').classList.contains('open')) {
            this.form.querySelector('[data-toggle="dropdown"]').click();
        }

        this.suggesting.pop();
        if (this.suggesting.length === 0) {
            this.form.querySelector('[data-target="destination-spinner"]').classList.add('hidden');
        }
    }

    printDestinations(destinations, destinationsContainer) {
        destinations.forEach((destination) => {
            destinationsContainer.appendChild(
                this.destinationManager.createDestinationElement(destination, this.onClickAction.bind(this))
            );
        });
    }

    onClickAction(element) {
        this.resetDestinations();
        this.selectDestination(element)
    }

    async suggest(text) {
        let data = {
            search: text
        };

        let res = await fetch(myApp.config.routing.searchSuggest, {
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
            method: "POST"
        });

        return await res.text();
    }

    resetDestinations() {
        this.form.querySelectorAll('[data-input-type]').forEach((element) => {
            element.value = '';
        });
        this.form.querySelector('[name="text"]').value = '';
        this.form.querySelector(this.submitButtonId).disabled = true;
    }

    selectDestination(destinationItem) {
        let text = destinationItem.querySelector('[data-target="destination-name"]').textContent;
        this.form.querySelector('#amimir-searcher-text').value = text;
        let inputType = destinationItem.getAttribute('data-type');
        this.form.querySelector('[data-input-type="' + inputType + '"]').value = destinationItem.getAttribute('data-id');
        this.form.querySelector('[name="text"]').value = text;
        this.form.querySelector(this.submitButtonId).disabled = false;
        this.form.querySelector('[data-toggle="dropdown"]').click();
    }

    createDestinationTypeElement(type) {
        let destinationItem = document.createElement('div');
        let destinationIcon = document.createElement('i');
        destinationIcon.classList.add("ci", type.icon, "mr-1");
        destinationItem.appendChild(destinationIcon);
        destinationItem.append(type.title);
        destinationItem.classList.add('destination-item', 'destination-type');

        return destinationItem;
    }

    onCloseCalendar() {
        myApp.browserHistoryManager.removeState(this.calendarId);
        this.formatDate();
    }

    onOpenCalendar() {
        myApp.browserHistoryManager.addState(this.calendarId);
    }

    formatDate() {
        let startInput = document.querySelector(this.calendarData.dates.start.dateInput);
        let endInput = document.querySelector(this.calendarData.dates.end.dateInput);

        if (startInput.value && endInput.value) {
            startInput.value = startInput.value.replace(/-/g, '/');
            endInput.value = endInput.value.replace(/-/g, '/');
        }
    }

    setDates(startDate, endDate) {
        this.bookingCalendarManager.calendar.element.selectedDates = [
            startDate,
            endDate,
        ];

        this.bookingCalendarManager.calendar.element.redraw();
        if (myApp.deviceDetector.isMdScreen() || myApp.deviceDetector.isLgScreen()) {
            this.bookingCalendarManager.calendar.jumpToDate(startDate);
        }
        this.bookingCalendarManager.onChangeBookingDates(
            [startDate, endDate],
            null,
            this.bookingCalendarManager.calendar.element
        );
        this.bookingCalendarManager.calendar.setView();
    }

    validateDates() {
        if (!this.calendarData) return true;
        let startInput = document.querySelector(this.calendarData.dates.start.dateInput);
        let endInput = document.querySelector(this.calendarData.dates.end.dateInput);

        return !!(startInput.value && endInput.value);
    }

    validateDestination(destinationContainer) {
        const hotelId = this.form.querySelector('input[name="hotelId"]');
        const cityId = this.form.querySelector('input[name="cityId"]');
        const regionId = this.form.querySelector('input[name="regionId"]');
        const text = this.form.querySelector('input[name="text"]');
        const countryId = this.form.querySelector('input[name="countryId"]');
        if (hotelId.value || cityId.value || regionId.value || text.value || countryId.value) {
            return true;
        }

        destinationContainer.classList.add('border-error');
        return false;
    }

    setDefaultDate() {
        this.setDates(this.startDate, this.endDate);
        this.formatDate();
    }
}
