import InputValidator from "@web/vpt-assets/assets/js/utilities/validations/inputValidator";

export default class NewsletterSubscriptionValidator {
    constructor() {
        this.inputValidator = new InputValidator();
        this.arrayFormsId = ['#form_invitation',
            '#form_newsletter',
            '#newsletter-banner',
            '#newsletter-banner-1',
            '#newsletter-banner-2'];
        this.eventDelegation();
    }

    eventDelegation() {
        this.arrayFormsId.forEach(idForm => {
            let form = document.querySelector(idForm);
            if (form){
                form.addEventListener("submit", event => {
                    event.preventDefault();
                    const inputMail = form.querySelector(' [name="email"]');
                    let error = true;

                    if (!this.inputValidator.validateEmail(null, inputMail, null)) {
                        inputMail.classList.add("border-error");
                        error = false;
                    }

                    if (error) {
                        let $form = $(event.target);
                        let modalPath = $form.data('wait-modal');
                        myApp.customModalManager.openModal('#' + $form.data('modal-id'), modalPath, undefined, undefined, $('#save'));
                        myApp.subscribeManager.subscribe($form.attr('id'));
                    }
                });
            }
        });
    }
}