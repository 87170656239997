export default class FormGroupValidator {

    validateGroup(form, group) {
        $("[data-error-group='" + group + "']").hide();
        $.each($("[data-form-group='" + group + "']"), (i, field) => {
            try {
                $(form).formValidation("validateField", $(field).attr("name"));
            } catch (e) {
                return false;
            }
            if (!$(form).data("formValidation").isValidField($(field).attr("name"))) {
                $("[data-error-group='" + group + "']").show();
            }
        });
    };
}
