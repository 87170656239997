export default class SearcherCacheValues {
    constructor() {
        let userAgent = navigator.userAgent;
        let isSafari = /iPhone|iPad/.test(userAgent) && (/Safari/.test(userAgent)) && !(/Chrome|CriOS|FxiOS/.test(userAgent));
        if (isSafari) {
            $('[data-searcher="open"][data-toggle="booking-dates"]').removeAttr('data-toggle');
        }
    }

    setCacheValues() {
        if (!myApp.browserStorageService.getBrowserStorageAvailable()) {
            return;
        }

        localStorage.setItem('adults', $('[data-counter-type=adults]').text());
        localStorage.setItem('children', $('[data-counter-type=children]').text());

        let rooms = $('[name=ha]');
        localStorage.setItem('rooms', rooms.children("option:selected").val());
        localStorage.setItem('start', $('[name=inicio]').val());
        localStorage.setItem('end', $('[name=fin]').val());
        localStorage.setItem('childrenAges', $('[name=edN]').val());

        let destinations = '';
        $(".active-item[data-destination-option=true]").each(function () {
            if (this.getAttribute('value') != "") {
                destinations += this.getAttribute('value');
                destinations += ";";
            }
        });
        localStorage.setItem('destinations', destinations);
    }

    checkLocalStorageDates() {
        if (!myApp.browserStorageService.getBrowserStorageAvailable()) {
            return;
        }
        let start = localStorage.getItem('start');
        let end = localStorage.getItem('end');
        if (!start || !end) {
            return false;
        }

        let today = new Date();
        let startDate = start.split("-");
        let endDate = end.split("-");
        let startDateObject = new Date(startDate[1] + "/" + startDate[0] + "/" + startDate[2]);
        let endDateObject = new Date(endDate[1] + "/" + endDate[0] + "/" + endDate[2]);
        if (startDateObject && endDateObject && startDateObject.getTime() < today.getTime()) {
            return false;
        }

        return true;
    }

    setCachedValues(calendar, distribution, destination) {
        if (!myApp.browserStorageService.getBrowserStorageAvailable()) {
            return;
        }

        let adults = localStorage.getItem('adults');
        let children = localStorage.getItem('children');
        let rooms = localStorage.getItem('rooms');
        let start = localStorage.getItem('start');
        let end = localStorage.getItem('end');
        let childrenAges = localStorage.getItem('childrenAges');
        let destinations = localStorage.getItem('destinations');

        if (!this.checkLocalStorageDates()) {
            return;
        }
        let datesObject = calendar.getDatesObject(start, end)
        calendar.setDates(datesObject[0], datesObject[1]);

        distribution.setDistributions(adults, children, childrenAges, rooms)

        if (destinations) {
            destination.setDestinations(destinations);
        }
    }
}
