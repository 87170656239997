export default class CustomMarker
{
    constructor(id, latitude, longitude, popupContent)
    {
        this.markers = {
            selected: {
                url: myApp.config.cdnPath.img + '/maps/marker-selected-shadow.png',
                width: 33,
                height: 49,
                anchor: {
                    x: 16,
                    y: 43
                }
            },
            unselected: {
                url: myApp.config.cdnPath.img + '/maps/marker-no-selected.svg',
                width: 27,
                height: 39,
                anchor: {
                    x: 13,
                    y: 34
                }
            }
        };

        this.marker = this.markers.unselected;
        this.id = id;
        this.latitude = latitude;
        this.longitude = longitude;
        this.popupContent = popupContent;

        this.icon = L.icon({
            iconUrl: this.marker.url,
            iconSize: [this.marker.width, this.marker.height],
            iconAnchor: [this.marker.anchor.x, this.marker.anchor.y],
            popupAnchor: [0, -this.marker.height/2],
        });
        this.createMarker();
    }

    createMarker()
    {
        this.marker = L.marker(
            [this.latitude, this.longitude],
            {icon: this.icon}
        ).bindPopup(this.popupContent);
    }

    openPopup()
    {
        this.marker.openPopup();
    }
}
