var AjaxManager = function () {

    "use strict";

    var self = this;

    
    this.ajaxCall = function (type, url, data, callback, element) {
        var hasSpinner = false;
        if (typeof element !== null && $(element).hasClass('form-status')) {
            hasSpinner = true;
            $(element).prop('disabled', true);
        }
        
        $.ajax({
            type: type,
            url: url,
            data: data,
            success: callback
        }).done(function () {
            if (hasSpinner) {
                $(element).prop('disabled', false);    
            }
        });
    };

};

export default AjaxManager;