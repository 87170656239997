var FormObserver = function () {

    "use strict";

    var self = this;

    this.initialize = function () {
        this.eventDelegation();
    };

    this.eventDelegation = function () {
        $("body").on("keyup", "[data-input-observer=counter]", function () {
            self.updateNumCharacters(this);
        });
        $("body").on("change", "[data-input-observer=dynamic-displayer]", function () {
            self.displayDynamicElements($(this).val(), $(this).data('parent'), $(this).data('target'), $(this).data('set-zero'));
        });
    };

    this.updateNumCharacters = function (input) {
        var target = $(input).data('observer-target');
        var charactersLeft = parseInt($(input).attr('maxLength')) - $(input).val().length;
        $($(input).data('observer-target')).html(charactersLeft);
    };

    this.displayDynamicElements = function (targetQuantity, targetContainer, target, targetReset) {
        var count = 1;
        if (targetQuantity > 0) {
            $(targetContainer + ' ' + target).hide();
            $(targetContainer).show();
            if ($(target).is("select")) {
                $(target).prop('selectedIndex', 0);
            } else {
                $(target).val('');
            }
            
            $(targetContainer + ' ' + target).each(function (index) {
                $(this).show();
                if (count == targetQuantity) {
                    return false;
                }
                count++;
            });
        } else {
            $(targetContainer).hide();
        }
    };
    this.initialize();
};

export default FormObserver;