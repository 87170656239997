var PopoverManager = function () {
    "use strict";

    var self = this;
    this.defaultDelayHide = 3000;

    this.initialize = function () {
        this.initializePopovers();
    };

    this.initializePopovers = function () {
        var trigger = 'hover';

        $.each($('[data-toggle="popover"]'), function () {
            var delayShow = 0;
            var delayHide = 0;

            if ($(this).data('trigger')) {
                trigger = $(this).data('trigger');
            }
            if (myApp.deviceDetector.isTouch()) {
                trigger = 'click';
            }

            if ($(this).data('delay-hide')) {
                delayHide = self.defaultDelayHide;
            }

            $(this).popover({
                trigger: trigger,
                html: true,
                delay: {
                    "show": delayShow,
                    "hide": delayHide
                },
                content: function () {
                    if ($(this).data('content')) {
                        return $($(this).data('content')).html();
                    }
                    else {
                        return $($(this).data('contentwrapper')).html();
                    }
                }
            })
        });
    };

    this.initializePopover = function (element, content) {
        var trigger = 'manual';
        var delayShow = 0;
        var delayHide = 0;

        element
            .popover({
            trigger: trigger,
            html: true,
            delay: {
                "show": delayShow,
                "hide": delayHide
            },
            content: function () {
                return $(content).html();
            }
        });
    };

    this.show = function (element) {
        element.popover('show');
    };

    self.hidePopover = function (element, e) {
        if (!$(element).is(e.target) && $(element).has(e.target).length === 0) {
            (($(element).popover('hide').data('bs.popover') || {}).inState || {}).click = false;
        }
    };
    this.initialize();
};

export default PopoverManager;