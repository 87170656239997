var TextTransformer = function () {
    "use strict";

    var self = this;

    this.initialize = function () {
        this.eventDelegation();
    };

    this.eventDelegation = function () {
        $("body").on('click', '[data-alternative-text]', function () {
            if ($(this).closest('[data-transformer-child]').length <=0 ) {
                self.changeText($(this));
            }
        });

        $("body").on('click', '[data-transformer-child]', function () {
            self.changeChildText($(this));
        });

        $("body").on("blur", "[data-copy-to]", function () {
            var currentText = $(this).val();
            var elementToCopy = $(this).data('copy-to');
            if ($(elementToCopy).is("input")) {
                $(elementToCopy).val(currentText);
            }
            else {
                $(elementToCopy).text(currentText);
            }
        });               
        
        $("body").on("keyup", "[data-copy-keyup]", function () {
            self.copydata(this);
        });
    };

    this.copydata = function (elem) {
        var value = '';
        $("[data-copy-keyup=" + $(elem).data('copy-keyup') + "]").each(function () {
            value += $(this).val();
        });
        $($(elem).data('copy-keyup-target')).val(value);
    };

    this.changeText = function (element) {
        var hasIcon = false;
        var originalText, alternativeText;
        originalText = element.data('original-text');
        alternativeText = element.data('alternative-text');
        element.data('alternative-text', originalText);
        element.data('original-text', alternativeText);
        
        if (element.find(".fa").length || element.find(".ci").length) {
            hasIcon = true;
            element.find("span").text(alternativeText);
        } else {
            element.html(alternativeText);
        }
        
        if (hasIcon) {
            var icon = element.find("[data-entity='icon']");
            if (icon) {
                myApp.collapseManager.changeCollapseIcon($(icon), $(icon).attr('data-currentclass'), $(icon).attr('data-futureclass'));
            }
        }
    };

    this.changeChildText = function (parent) {
        var child = parent.data('transformer-child');
        var childElement = parent.find("[data-element='"+child+"']");
        self.changeText($(childElement));
    };

    this.shortenText = function (start, limit, originalText, dots) {
        var text = originalText.substring(start, limit);
        if (dots && originalText.length > limit) {
            text = text + "...";
        }
        return text;
    };

    this.initializeTextElement = function (element) {
        var textElement;
        if (element.find(".fa").length) {
            textElement = element.find("span").text();
        } else {
            textElement = element.text();
        }
        if (textElement !== element.data("default-text")) {
            this.changeText(element);
        }
    };
    this.initialize();
};

export default TextTransformer;