import CookieManager from "./cookie/cookieManager";

export default class RedirectLanguage {
    constructor() {
        this.clientCountryCookie = 'hide-client-country';
        this.cookieManager = new CookieManager();
        this.eventDelegation();
    }

    eventDelegation() {
        this.initializeClientCountry();
    }

    initializeClientCountry() {
        let clientCountryContainer = $('[data-container="client-country"]');
        if (clientCountryContainer.length === 0 || this.cookieManager.getCookie(this.clientCountryCookie)) return;
        clientCountryContainer.show();
        $('[data-action="hide-client-country"]').on('click', () => {
            clientCountryContainer.remove();
            this.cookieManager.setCookie(this.clientCountryCookie, 'hidden',7,'/');
        });
    }
}