import CustomMap from "../../entity/customMap/customMap";
import CustomMarker from "../../entity/customMap/customMarker";

export default class CustomMapBuilder
{
    constructor()
    {
        this.arrayMaps = [];
    }

    addMap(mapId, latitude, longitude, zoom)
    {
        let map = this.getMap(mapId);
        if (!(map instanceof CustomMap)) {
            map = new CustomMap(mapId, latitude, longitude, zoom);
            this.arrayMaps.push(map);
        }
        return map;
    }

    addMarker(mapId, markerId, latitude, longitude, popupContent)
    {
        const map = this.getMap(mapId);
        let marker = map.getMarker(markerId);
        if (!(marker instanceof CustomMarker)) {
            marker = new CustomMarker(markerId, latitude, longitude, popupContent);
            map.addMarker(marker);
        }
        return marker;
    }

    removeMap(mapId)
    {
        for (let [i, map] of this.arrayMaps.entries()) {
            if (map.id === mapId) {
                (this.arrayMaps).splice(i, 1);
                return;
            }
        }
    }

    getMap(mapId)
    {
        for (let map of this.arrayMaps) {
            if (map.id === mapId) {
                return map;
            }
        }
    }
}