var Form = function (formId) {

    "use strict";

    var self = this;

    this.type = '';
    this.callbackFunction;    
    this.selector;
    this.id = formId;
    this.form = $('#' + this.id);

};

Form.prototype.getForm = function () {
    this.form = $('#' + this.id);

    return this.form;
};

Form.prototype.getAction = function () {
    return this.form.data('action');
};

Form.prototype.setSelector = function (selector) {
    this.selector = selector;
};

Form.prototype.setType = function (type) {
    this.type = type;
};

Form.prototype.validate = function () {
    var validated = true;
    
    try {
        if(this.form.data('formValidation').validate()) {
            validated = this.form.data('formValidation').isValid();
        }
    } catch (e) {
    }
    
    return validated;
};

Form.prototype.setCallback = function (callbackFunction) {
    this.callbackFunction = callbackFunction;
};

Form.prototype.executeCallback = function (data) {
    this.callbackFunction(data);
};

Form.prototype.disableFields = function (container) {
    var id = this.id;
    $.each($('#' + this.id).serializeArray(), function (index, input) {
        var field = $('#' + id + ' ' + container + ' [name="' + input.name + '"]');
        field.attr('disabled', 'disabled');
    });
};

Form.prototype.enableFields = function (container) {
    var id = this.id;
    $.each($('#' + this.id + ' input:disabled'), function (index, input) {
        var field = $('#' + id + ' ' + container + ' [name="' + input.name + '"]');
        field.removeAttr('disabled');
    });
};

export default Form;