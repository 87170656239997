import SearcherManager from "./searcherManager";

export default class Searcher {
    constructor(id) {
        this.id = id;
        this.element = $('#' + id);
        this.maxAdultsLimit = 20;
        this.maxChildrenLimit = 10;
        this.minAdultsLimit = 1;
        this.minChildrenLimit = 0;
    }

    getInputValue(input) {
        let inputElement = this.element.find('[name="' + SearcherManager.inputs[input] + '"]');
        return inputElement ? inputElement.val() : null;
    }

    setInputValue(input, value) {
        let inputElement = this.element.find('[name="' + SearcherManager.inputs[input] + '"]');
        if (inputElement) {
            inputElement.val(value)
        }
    }

    static get inputs() {
        return {
            start: 'inicio',
            end: 'fin',
            bookingDates: '#booking-dates',
            adults: 'nA',
            children: 'nNe',
            childrenAges: 'edN',
            rooms: 'ha'
        }
    }
}
