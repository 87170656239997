export default class FormPrinter {
    toggleValidateFieldsContainer(container, validate) {
        const form = $(container).closest("form");
        $.each($(container), function (i, input) {
            const field = $(input).attr('name');
            form.formValidation('enableFieldValidators', field, validate);
        });
    };

    revalidateFieldsContainer(container) {
        const form = $(container).closest("form");
        $.each($(container), function (i, input) {
            form.formValidation('revalidateField', $(input).attr('name'));
        });
    };

    resetFieldsContainer(container) {
        const form = $(container).closest("form");
        $.each($(container), function (i, input) {
            $(input).val("");
            form.data('formValidation').resetField($(input).attr('name'));
        });
    };
};
