export default class CookieManager
{
    setCookie(cookieName, content, expiresDays, path = null)
    {
        let date = new Date;
        date.setDate(date.getDate() + expiresDays);

        document.cookie = `${cookieName}=${encodeURIComponent(content)}; path=${path}; expires=${date.toUTCString()}`;
    }

    getCookie(cookieName)
    {
        const name = cookieName + "=";
        const ca = document.cookie.split(';');

        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0)==' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length,c.length);
            }
        }
        return "";
    }
}
