var DomManager = function () {

    var self = this;

    this.initialize = function () {
        this.eventDelegation();
    };

    this.eventDelegation = function () {
        $('[data-toggle-handler=status]').on("click", function () {
            self.toggleStatus($(this).data('target'), $(this).data('nontarget'), $('[data-toggle-item=' + $(this).data('toggle-item') + ']'));
        });
        $("body").on("click", "[data-action='select-related-element']", function () {
            var relatedElement = $(this).data('related-element');
            $(relatedElement).prop('checked', true);
        });
        $("body").on("click", "[data-hide-container]", function () {
            $(this).closest("[data-container]").fadeOut();
        });
    };

    this.toggleClass = function (target, noTarget, elemClass) {
        noTarget.removeClass(elemClass);
        target.addClass(elemClass);
    };

    this.fillContainer = function (container, field, content) {
        if (content) {
            $(field).text(content);
            $(container).show();
        } else {
            $(container).hide();
        }
    };

    this.toggleStatus = function (target, nonTarget, elem) {
        $(target).show();
        $(nonTarget).hide();
        $(elem).each(function (index) {
            $(this).data('target', nonTarget);
            $(this).data('nontarget', target);
        });

    };
    this.initialize();
};

export default DomManager;