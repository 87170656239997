var DropdownManager = function () {
    "use strict";

    var self = this;

    this.initialize = function () {
        this.eventDelegation();
    };

    this.eventDelegation = function () {
        $(window).on("orientationchange", function () {
            $('.dropdown-menu').attr('style', '');
            $(".dropdown-hover-lg").removeClass('open');
        });

        $(".dropdown-hover-lg > a").on("click", function (event) {
            if (myApp.deviceDetector.isTouch()) {
                event.preventDefault();
                $(event.currentTarget).closest('.dropdown-hover-lg').toggleClass('open');
            }
        });

        $('#menu_overlay').on('touchstart', function () {
            self.closeDropdown();
        });

        $("[data-dropdown][data-dropdown-overlay]").on("show.bs.dropdown", function(){
            if ($(this).data('hide-overlay') === 'md') {
                if (myApp.deviceDetector.isXsScreen() || myApp.deviceDetector.isSmScreen()) {
                    $('#menu_overlay').fadeIn();
                }
            } else {
                $('#menu_overlay').fadeIn();
            }
        });

        $("[data-dropdown][data-dropdown-overlay]").on("hidden.bs.dropdown", function(){
            $('#menu_overlay').fadeOut();
        });
    };

    this.closeDropdown = function () {
        $.each($('[data-dropdown][data-dropdown-overlay]'), function () {
            if($(this).hasClass('open')){
                $(this).removeClass('open');
                $('#menu_overlay').fadeOut();
            }
        });
    };

    this.initialize();
};

export default DropdownManager;
