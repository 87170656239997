var DeviceDetector = function () {

    var self = this;

    this.allowTouch = false;
    this.deviceSize;
    this.deviceUserAgent;
    this.windowWidth;

    this.initialize = function () {
        this.detectDeviceUserAgent();
        this.eventDelegation();
        this.detectTouch();
        this.detectWindowWidth();
        this.detectDeviceSize();
    };    

    this.eventDelegation = function () {
        if (self.isXsScreen() || self.isSmScreen()) {
            $("body").on('click', '#closeApp', function () {
                $("#app-redirect").removeClass('in');
                myApp.browserStorageService.setItem('closeApp', 'true');
            });

            if (!myApp.browserStorageService.getItem('closeApp')) {
                $("#app-redirect").addClass('in');
            }
        }
        
        $(window).on("resize", function (event) {
            myApp.deviceDetector.detectWindowWidth();
        });
        
    };

    this.detectTouch = function () {
        try {
            document.createEvent("TouchEvent");
            this.allowTouch = true;
        } catch (e) {
            this.allowTouch = false;
        }
    };

    this.isTouch = function () {
        return this.allowTouch;
    };
    
    this.detectWindowWidth = function () {
        this.windowWidth = window.innerWidth;
    };
    
    this.isXsScreen = function () {
        return this.windowWidth < 768;
    };

    this.isSmScreen = function () {
        return this.windowWidth > 767 && this.windowWidth < 991;
    };

    this.isMdScreen = function () {
        return this.windowWidth > 990 && this.windowWidth < 1199;
    };

    this.isLgScreen = function () {
        return this.windowWidth > 1198;
    };

    this.detectDeviceSize = function () {
        if (this.isXsScreen()) {
            this.deviceSize = 'xs';
        } else if (this.isSmScreen()) {
            this.deviceSize = 'sm';
        } else if (this.isMdScreen()) {
            this.deviceSize = 'md';
        } else if (this.isLgScreen()) {
            this.deviceSize = 'lg';
        }
    };

    this.setAttributesByDevice = function (devices, elements, attributes) {

        var apply;

        $.each(devices, function (key, deviceSize) {
            switch (self.deviceSize) {
                case 'xs':
                    if (self.isXsScreen() && self.isTouch()) {
                        apply = true;
                    }
                    break;
                case 'sm':
                    if (!self.isSmScreen() && self.isTouch()) {
                        apply = true;
                    }
                    break;
                case 'md':
                    if (!self.isMdScreen()) {
                        apply = true;
                    }
                    break;
                case 'lg':
                    if (!self.isLgScreen()) {
                        apply = true;
                    }
                    break;
            }
            if (apply) {
                $.each(elements, function (key, element) {
                    $.each(attributes, function (attribute, value) {
                        $(element).attr(attribute, value);
                    });
                });
            }
        });
    };

    this.detectDeviceUserAgent = function () {
        if (navigator.userAgent.match(/(iPhone)/)) {
            this.deviceUserAgent = 'iPhone';
        } else if (navigator.userAgent.match(/(iPad)/)) {
            this.deviceUserAgent = 'iPad';
        } else if (navigator.userAgent.match(/(Windows Phone)/)) {
            this.deviceUserAgent = 'Windows Phone';
        } else if (navigator.userAgent.match(/(Android)/)) {
            this.deviceUserAgent = 'Android';
        }
    };

    this.detectDeviceBrowser = function () {
        var result = '';
        if (navigator.userAgent.search("MSIE") >= 0) result = 'ie';
        else if (navigator.userAgent.search("Chrome") >= 0) result = 'chrome';
        else if (navigator.userAgent.search("Firefox") >= 0) result = 'firefox';
        else if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) result = 'safari';
        else if (navigator.userAgent.search("Opera") >= 0) result = 'opera';
        return result;
    };
    this.initialize();
};

export default DeviceDetector;