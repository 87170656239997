var AffixManager = function () {

    "use strict";

    var self = this;

    this.initialize = function () {
        this.eventDelegation();
        myApp.scrollManager.addScrollFunction(this.doAffixScroll);
    };

    this.eventDelegation = function () {
        $('[data-entity=affix-header]').on('affix.bs.affix', function () {
            $('body').css('padding-top', $('[data-entity=affix-header]').height() + 10);
        });

        $('[data-entity=affix-header]').on('affix-top.bs.affix', function () {
            $('body').css('padding-top', '0');
        });

        if(myApp.deviceDetector.isMdScreen() || myApp.deviceDetector.isLgScreen()) {
            this.doAffixScroll();
        }
    };

    this.doAffix = function (element, breakPointPosition) {
        var windowPosition = $(window).scrollTop();
        if (windowPosition >= element.attr('data-offset-top') && windowPosition <= breakPointPosition && element.hasClass('affix-top')) {
            self.addAffix(element);
        } else if ((windowPosition < element.attr('data-offset-top') || windowPosition > breakPointPosition) && element.hasClass('affix')) {
            self.removeAffix(element);
        }
    };

    this.addAffix = function (element) {
        $('body').css("padding-top", element.outerHeight());
        element.removeClass('affix-top').addClass('affix');
    };

    this.removeAffix = function (element) {
        $('body').css("padding-top", "0");
        element.removeClass('affix').addClass('affix-top');
    };

    const openExclusiveDialogModal = () => {
        const hasExclusiveModal = document.querySelector('#exclusiveDialogModal');
        const hasCookie = myApp.browserStorageService.findCookie('hide_exclusive_banner');

        if (hasExclusiveModal && !hasCookie && myApp.customModalManager && myApp.deviceDetector.isLgScreen()) {
            myApp.customModalManager.openModal('#exclusiveDialogModal');
            myApp.browserStorageService.setCookie('hide_exclusive_banner', '7-d');
        }
    }

    this.doAffixScroll = function () {

        $.each($('[data-affix="scroll"]'), function () {
            let element = $(this);
            var affixContainer = element.closest('[data-affix-container="true"]');
            var scrollTop = myApp.scrollManager.getScrollTop();
            let affixContainerScrollTop = $(affixContainer).offset().top;
            var offsetBottom = parseInt(affixContainer.offset().top) + parseInt(affixContainer.height());
            
            if (scrollTop > (affixContainerScrollTop + element.height())) {
                if(!element.hasClass('affix')){
                    element.addClass('affix');
                    openExclusiveDialogModal();
                }
            } else {
                if(element.hasClass('affix')){
                    element.removeClass('affix');
                }
            }
            
            if ((scrollTop + $(window).height() > offsetBottom) && element.hasClass('affix')) {
                element.removeClass('affix').addClass('affix-bottom');
            } else {
                element.removeClass('affix-bottom');
            }
        });
    };
    this.initialize();
};

export default AffixManager;
