import myAppConfig from "../../myApp.config";

export default class UserExclusiveService {
    constructor() {
        this.url = new URL(window.location.href);
        this.exclusiveModalSelector = '#userExclusiveModal';
        this.hasParam = this.url.searchParams.has('userExclusive');
        this.openRegisterModal = document.querySelector('[data-open="register-user"]');
        if (this.hasParam && document.querySelector(this.exclusiveModalSelector)) {
            this.openModal();
        }
        this.eventDelegation();
    }

    eventDelegation() {
        const registerButton = "register-user";
        const loginButton = "login-user";

        if (this.hasParam) {
            document.querySelectorAll('[data-action="close-exclusive-modal"]').forEach((item) => {
                item.addEventListener('click', (e) => {
                    const modalSelector = e.currentTarget;
                    document.querySelector(this.exclusiveModalSelector).addEventListener('custom-modal-hidden', (event) => {
                        myApp.customModalManager.openModal('#userModal');
                        if (modalSelector.getAttribute('data-entity') === registerButton) {
                            myApp.user.loginManager.openRegisterModal();
                        } else {
                            myApp.user.loginManager.openLoginModal();
                        }
                    });
                    myApp.customModalManager.closeModal(this.exclusiveModalSelector);
                });
            });
        } else {
            document.querySelectorAll('[data-action="close-exclusive-modal"]').forEach((item) => {
                item.addEventListener('click', (e) => {
                    const modalSelector = e.currentTarget;
                    myApp.customModalManager.openModal('#userModal');
                    if (modalSelector.getAttribute('data-entity') === registerButton) {
                        myApp.user.loginManager.openRegisterModal();
                    } else {
                        myApp.user.loginManager.openLoginModal();
                    }
                });
            });
        }

        const userExclusive = document.querySelector('[data-entity="bookingUserExclusive"]');
        if (userExclusive) {
            userExclusive.addEventListener('click', (e) => {
                const exclusiveChollos = document.querySelector('[data-entity="bookingUserExclusive"]');
                if (this.hasParam && exclusiveChollos) {
                    this.closeModal();
                } else {
                    window.location.href = window.location.origin + '/';
                }
            });
        }
        if (this.openRegisterModal) {
            this.openRegisterModal.addEventListener('click', () => {
                myApp.customModalManager.openModal('#userModal');
                myApp.user.loginManager.openRegisterModal();
            });
        }
    }

    openModal() {
        myApp.customModalManager.openModal(this.exclusiveModalSelector);
    }

    closeModal() {
        myApp.customModalManager.closeModal(this.exclusiveModalSelector);
    }
}
