export default class CounterHandler {
    constructor() {
        this.activeClass = 'active';
        this.eventDelegation();
    }

    eventDelegation() {
        $('[data-counter-action]').on('click', (event) => {
            let currentTarget = $(event.currentTarget);
            let counter = $(currentTarget.closest('[data-counter]'));
            let action = currentTarget.data('counter-action');
            this.setCounter(counter, action);
        });
    }

    setCounter(counter, action) {
        let counterValueElement = counter.find('[data-counter-value]');
        let counterName = counter.data('counter');
        let counterActionDecreaseElement = document.querySelectorAll('[data-counter="'+counterName+'"] [data-counter-action="decrease"]')[0];
        let counterActionIncreaseElement = document.querySelectorAll('[data-counter="'+counterName+'"] [data-counter-action="increase"]')[0];
        let currentValue = counterValueElement.data('counter-value');
        let maxLimitValue = counter.data('max-limit');
        let minLimitValue = counter.data('min-limit');

        if (action === 'decrease') {
            currentValue = this.decreaseCounter(currentValue, minLimitValue, counterActionDecreaseElement, counterActionIncreaseElement);
        }

        if (action === 'increase') {
            currentValue = this.increaseCounter(currentValue, maxLimitValue, counterActionDecreaseElement, counterActionIncreaseElement);
        }

        counterValueElement.data('counter-value', currentValue);
        counterValueElement.text(currentValue);
        counter.trigger('change', [currentValue]);
    }

    increaseCounter(currentValue, maxLimitValue, counterActionDecreaseElement, counterActionIncreaseElement) {
        counterActionDecreaseElement.classList.add(this.activeClass);
        if ((currentValue + 1) >= maxLimitValue) {
            currentValue = maxLimitValue;
            counterActionIncreaseElement.classList.remove(this.activeClass);
        } else {
            currentValue++;
            counterActionIncreaseElement.classList.add(this.activeClass);
        }

        counterActionDecreaseElement.style.display='none';
        counterActionDecreaseElement.offsetHeight;
        counterActionDecreaseElement.style.display='';

        counterActionIncreaseElement.style.display='none';
        counterActionIncreaseElement.offsetHeight;
        counterActionIncreaseElement.style.display='';

        return currentValue;
    }

    decreaseCounter(currentValue, minLimitValue, counterActionDecreaseElement, counterActionIncreaseElement) {
        counterActionIncreaseElement.classList.add(this.activeClass);
        counterActionIncreaseElement.classList.add(this.activeClass);
        if ((currentValue - 1) <= minLimitValue) {
            currentValue = minLimitValue;
            counterActionDecreaseElement.classList.remove(this.activeClass);
        } else {
            currentValue--;
            counterActionDecreaseElement.classList.add(this.activeClass);
        }

        counterActionDecreaseElement.style.display='none';
        counterActionDecreaseElement.offsetHeight;
        counterActionDecreaseElement.style.display='';

        counterActionIncreaseElement.style.display='none';
        counterActionIncreaseElement.offsetHeight;
        counterActionIncreaseElement.style.display='';
        return currentValue;
    }
}
