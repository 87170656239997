var myAppConfig = function () {
    this.rootPath = window.location.origin ? window.location.origin + '/' : window.location.protocol + '/' + window.location.host + '/';
    this.cdnPath = new Array();
    this.cdnPath.img = window.location.protocol + "//contentimg.buscounchollo.com/img/web";
    this.cdnPath.contentimg = window.location.protocol + "//content.buscounchollo.com/img";
    this.analytics = new Array();
    this.analytics.ua = "UA-742758-3";

    this.breakpoint = {
        xsMini: 576,
        xs: 768,
        sm: 992,
        md: 1200,
    };
};

export default myAppConfig