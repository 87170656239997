import DataLayerManager from "./dataLayerManager";

export default class DataLayerGenerator {
    constructor() {
        this.dataLayerManager = new DataLayerManager();
    }
    generate(events) {
        Object.keys(events).forEach((k) => events[k] == null && delete events[k]);
        this.dataLayerManager.pushData(JSON.stringify(events));
    }
}