export default class CopyTextManager
{
    constructor()
    {
        this.eventDelegation();
    }

    eventDelegation()
    {
        let copyButton = $('[data-action="copyText"]');
        let copyText = $('[data-entity="copyText"]');
        copyButton.on('click', (event) => {
            if (copyText.text()) {
                navigator.clipboard.writeText(copyText.text());
            }
            $('[data-entity="copyAlert"]').fadeIn().delay(1000).fadeOut();
        });
    }
}