var ScrollManager = function () {

    "use strict";

    var self = this;

    this.didScroll = false;
    this.observeInterval = 600;
    this.scrollRang = 30;
    this.lastScrollTop = null;
    this.remainingFunctionsArray = [];
    this.deviceDetector;
    this.scrollFunctions = [];

    this.initialize = function (deviceDetector) {
        this.deviceDetector = deviceDetector;
        if ($('[data-entity=sticky-bottom-resume]').length > 0) {
            this.moveScrollTopButton();
        }
        this.initializeEventDetection();
        this.initializeDevicesFunctions();
        this.observeScroll();
        this.scrollToAnchor();
        self.addRemainingFunction(myApp.initializer.arrowToTop);
        this.eventDelegation();
    };

    this.eventDelegation = function () {
        $('[data-scroll-to-xs]').on('click', function () {
            if (myApp.deviceDetector.isXsScreen()) {
                self.scrollToElement($(this))
            }
        });

        document.addEventListener('scroll', () => {
            this.checkScrollUp()
            for (let scrollFunction of this.scrollFunctions) {
                scrollFunction();
            }
        });

        window.addEventListener('resize', self.moveScrollTopButton);

        $('body').on('click', '[data-scroll-into-onclick]', function() {
            if (this.ariaExpanded == 'true') {
                document.querySelector(this.dataset.scrollIntoOnclick).scrollIntoView({
                    block: "start",
                    behavior: "smooth"
                });
            }
        })
    };

    this.checkScrollUp = function () {
      let element = $('[data-action="scroll-up-fixed"]');
      if(element.length === 0) {
          return;
      }

      let scrollTop = this.getScrollTop();
      if (!(scrollTop > (this.lastScrollTop + this.scrollRang) || scrollTop < (this.lastScrollTop - this.scrollRang))) {
          return;
      }

      let elementTop;
      if(this.lastScrollTop && scrollTop > this.lastScrollTop && scrollTop > 0) {
          elementTop = -element.outerHeight();
          element.css('top', elementTop);
          if(this.lastElementTop !== elementTop) {
                this.lastElementTop = elementTop;
            }
      } else {
          elementTop = $('#header .menu-navigation').outerHeight();
          element.css('top', elementTop);
          if(this.lastElementTop !== elementTop) {
              this.lastElementTop = elementTop;
          }
      }

      this.lastScrollTop = scrollTop;
    };
    this.initializeEventDetection = function () {
        $("[data-scrollable]").add(window).scroll(function () {
            self.doScroll(true);
        });
        $('body').on('click', '.collapse-scroll', function () {
            var collapse = $(this).attr('href');
            myApp.collapseManager.unidirectionalCollapse(collapse);
        });
        $("body").on('click', '.scroll-top-wrapper', function () {
            self.scrollToElement('html');
        });
        $("body").on('click', '[data-scrollto]', function (event) {
            if ($(this).data('scrollto') != '' && $(this).data('scrollto') != '.') {
                myApp.scrollManager.scrollToElement($(this).data('scrollto'));
            }
        });
        $("body").on('click', '[data-scrollto-container]', function (event) {
            self.scrollToContainer($(this).data('scrollto-container'))
        });
    };

    this.initializeDevicesFunctions = function () {
        if (!this.deviceDetector.isXsScreen()) {
            if (!$('body').hasClass('sticky_header')) {
                this.addRemainingFunction(this.toogleFixedSiteHeader);
            }
        }
    };

    this.scrollToAnchor = function () {
        var scrollAnchor = $("[data-scroll-anchor-mobile]");
        var isMobileLayout = (self.deviceDetector.isXsScreen() || self.deviceDetector.isSmScreen());
        if (isMobileLayout && scrollAnchor.length > 0) {
            self.scrollToElement(scrollAnchor);
        }
    };

    this.getScrollTop = function () {
        var scrollTop = $(window).scrollTop();
        return scrollTop;
    };

    this.doScroll = function (scrolled) {
        this.didScroll = scrolled;
    };

    this.scrollToContainer = function (element) {
        $('html, body').animate({
            scrollTop: $(element).offset().top - 15
        }, 450);
    }

    this.scrollToElement = function (element, timeout, customOffset = 0) {
        var height = 80;
        if ($(element).attr('data-height')) {
            height = $(element).attr('data-height');
        }

        height += customOffset;
        timeout = timeout || 0;
        setTimeout(function () {
            if (typeof element === 'string' && element.includes("-m")){ //If the scroll is in modal.
                var container;
                if (myApp.deviceDetector.isXsScreen() || myApp.deviceDetector.isSmScreen() ){
                    container = $(element).closest('.modal-body');
                    if (container && container.length > 0){
                        container.animate({
                            scrollTop: $(element).offset().top - container.offset().top +
                                container.scrollTop() - 15
                        }, 450);
                    }
                }else{
                    container = $(element).closest('.modal');
                    if (container && container.length > 0) {
                        container.animate({
                            scrollTop: $(element).offset().top - container.offset().top +
                                container.scrollTop() - 15
                        }, 450);
                    }
                }
            }else{
                $('html, body').animate({
                    scrollTop: $(element).offset().top - height - 15
                }, 450);
            }
        }, timeout);
    };
    this.scrollContainerToElement = function (container, element) {
        $(container).animate({
            scrollTop: $(element).position().top - $(container).position().top + $(container).scrollTop()
        }, 450);
    };

    this.addRemainingFunction = function (remainingFunction) {
        this.remainingFunctionsArray.push(remainingFunction);
    };

    this.observeScroll = function () {
        setInterval(function () {
            if (self.didScroll) {
                self.doScroll(false);
                self.remainingFunctionsArray.forEach(function (remainingFunction) {
                    if (typeof remainingFunction === "function") {
                        remainingFunction();
                    }
                });
            }
        }, this.observeInterval);
    };

    this.isScrollable = function (elem) {
        return elem.get(0).scrollHeight > elem.height();
    };

    this.scrollOn = function (container, element) {
        if (this.isScrollable($(container))) {
            this.scrollContainerToElement(container, element);
        } else {
            this.scrollToElement(element);
        }
    };

    this.addScrollFunction = function (functionScroll) {
        this.scrollFunctions.push(functionScroll);
    }

    this.moveScrollTopButton = function () {
        let height = $('[data-entity=sticky-bottom-resume]:visible').outerHeight() + 10 || '';
        $('.scroll-top-wrapper').css('bottom', height);
    }

    this.initialize(myApp.deviceDetector);
};

export default ScrollManager;
